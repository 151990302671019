import store from '@/state/store'

export default [
    {
        path: '/',
        meta: {
            authRequired: true
        },
                component: () => import('./views/Home.vue'), // Make sure the path is correct
    },
    {
        path: '/analytics',
        meta: {
            authRequired: true
        },
        component: () => import('./views/Analytics.vue'), // Make sure the path is correct
    },

    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? {
                    name: 'default'
                } : {
                    ...routeFrom
                })
            },
        },
    }
]